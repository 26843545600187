.App {
  display: flex;
}

.react-flow__attribution {
  display: none;
}

.react-flow__node-image {
  z-index: -9999 !important;
}
